import * as React from "react";
import {
  Row,
  Col,
  Divider,
  Button,
  Form,
  Input,
  Select,
  notification,
} from "antd";
import HeaderComp from "../components/header";
import "../components/layout.css";
import TextArea from "antd/lib/input/TextArea";
import FooterComp from "../components/footer";
// import axios from "axios";

const { Option } = Select;
const ContactPage = () => {
  const [form] = Form.useForm();
  const handleSubmit = (value) => {
    console.log("values", value);
    let contactUrl =
      "https://2tz6e74yv6.execute-api.us-east-2.amazonaws.com/contact";
    const getFeildsValues = {
      name: `${value.name}`,
      email: `${value.email}`,
      phone: `${value.phone}`,
      //   phone_country_code: `${value.prefix}`,
      //   webinar_id: "65",
      position: `${value.position}`,
      desc: `${value.moreInfo}`,
      page_referrer: "https://google.com/",
    };
    // axios({
    //   url: contactUrl,
    //   method: "POST",
    //   async: true,
    //   crossDomain: true,
    //   data: getFeildsValues,
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((response) => {
    //   {
    //     notification["success"]({
    //       message: JSON.parse(response.data.data.processPipeline)
    //         .ResponseMessage,
    //     });
    //   }
    //   form.resetFields();
    // });
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{
          width: 70,
        }}
      >
        <Option value="91">+91</Option>
      </Select>
    </Form.Item>
  );

  return (
    <>
      <HeaderComp />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1920,
          // padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <div
          className="careerBanner"
          data-sal="fade"
          data-sal-duration="1000"
          data-sal-delay="2000"
          data-sal-easing="linear"
        >
          <Row className="enterpriseTitleRow">
            <Col span={24}>
              <h1
                className="careersTitle"
                data-sal="slide-right"
                data-sal-duration="2000"
                data-sal-delay="1000"
                data-sal-easing="linear"
              >
                Careers
              </h1>
            </Col>
          </Row>
        </div>
        <div style={{ padding: "5% 5% 0" }}>
          <Row>
            <Col
              xs={{ span: 24 }}
              lg={{ span: 10 }}
              xl={{ span: 10 }}
              xxl={{ span: 10 }}
            >
              <h1 className="careerTitle">
                Work at CW Solutions where Technology meets Simplicity.
              </h1>
              <p className="careerPara">
                At CW Solutions, we believe that ideas can transform the most
                complex problems into simple solutions. CW Solutions offers a
                challenging and performance driven environment where results are
                recognized and rewarded. We believe that the team members
                discipline, integrity, and commitment to excellence are assets
                that will add significant value to our company.
              </p>
              <p className="careerPara">
                CW Solutions believe in Equal Opportunity for every individual
                and does not discriminate in employment opportunities or
                practices on the basis of race, colour, creed, religion, sex,
                gender identity or expression, sexual orientation, disability,
                marital status, or age.
              </p>
              <p className="careerPara">
                We develop software that transforms the traditional solutions to
                a Next Generation solution ensuring the boost in the performance
                and an increase in the productivity. At CW Solutions, every
                voice & every action contributes to growth of our stakeholders.
                We use seamless ERP strategies and retail technologies to build
                solutions that help enterprises better their profits.
              </p>
              <p className="careerPara">
                We invite you to be part of a diverse team of thinkers and
                doers.
              </p>
            </Col>
            <Col
              xs={{ span: 24 }}
              lg={{ span: 14 }}
              xl={{ span: 14 }}
              xxl={{ span: 14 }}
            >
              <div className="careerFormDiv">
                <Form
                  form={form}
                  name="contactForm"
                  onFinish={handleSubmit}
                  initialValues={{
                    name: "",
                    email: "",
                    phone: "",
                    city: "",
                    moreInfo: "",
                    prefix: "91",
                  }}
                >
                  <Row>
                    <Col span={24}>
                      <label className="contactFieldLabel">Name*</label>
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your firstname",
                          },
                        ]}
                      >
                        <Input className="contactFieldInput" size="large" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <label className="contactFieldLabel">
                        Email Address*
                      </label>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your email address",
                          },
                        ]}
                      >
                        <Input
                          type="email"
                          className="contactFieldInput"
                          size="large"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <label className="contactFieldLabel">
                        Mobile Number*
                      </label>
                      <Form.Item
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: "Please enter mobile number",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          className="contactFieldInput"
                          size="large"
                          addonBefore={prefixSelector}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <label className="contactFieldLabel">
                        Position that suits your Experience*
                      </label>
                      <Form.Item
                        name="position"
                        rules={[
                          {
                            required: true,
                            message: "Please enter position",
                          },
                        ]}
                      >
                        <Input className="contactFieldInput" size="large" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <label className="contactFieldLabel">
                        More About yourself
                      </label>
                      <Form.Item name="moreInfo">
                        <TextArea rows={6} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <label className="contactFieldLabel">
                        Upload your CV*
                      </label>
                        <form action="/action_page.php">
                          <input type="file" id="myfile" name="myfile" />
                        </form>
                    </Col>
                  </Row>
                  <Row>
                    <Button
                      type="primary"
                      size="large"
                      htmlType="submit"
                      className="careerSubmitBtn"
                    >
                      Apply
                    </Button>
                  </Row>
                </Form>
                <Divider />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <FooterComp />
    </>
  );
};

export default ContactPage;
